<template>
    <div class="ces-main" style="height: 100%">
        <div class="boxbutton">
            <el-row>
                <el-col :span="16">
                    <div class="grid-content bg-purple">
                        <!--<el-button type="primary" plain size="mini" v-if="userManagementSearchFlag" @click="userManagementSearch">查询</el-button>-->
                        <el-button type="primary" plain size="mini" v-if="userManagementAddFlag" @click="userManagementAdd">新增</el-button>
                        <el-button type="primary" plain size="mini" v-if="userManagementModifyFlag" @click="userManagementModify">修改</el-button>
                        <el-button type="primary" plain size="mini" v-if="userManagementFrozenFlag" @click="userManagementFrozen">冻结</el-button>
                        <el-button type="primary" plain size="mini" v-if="userManagementDelFlag" @click="userManagementDel">删除</el-button>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="grid-content bg-purple">
                        <el-input maxlength="50" placeholder="请输入用户姓名" v-model="AccurateAndUnique_name" size="mini" style="width: 250px"  class="input-with-input">
                            <el-button slot="append" icon="el-icon-search" @click="handleQuery"></el-button>
                        </el-input>
                        <el-button type="primary" size="mini" icon="el-icon-zoom-in" style="float: right" @click="dialog = true">高级查询</el-button>
                    </div>
                </el-col>
                <el-drawer
                        title="用户查询条件"
                        :before-close="cancelForm"
                        :visible.sync="dialog"
                        :with-header="false"
                        direction="rtl"
                        custom-class="demo-drawer"
                        ref="drawer"
                >
                    <div class="demo-drawer__content">
                        <el-row>
                            <el-col>
                                <div class="grid-content bg-purple">
                                    <el-card class="box-card">
                                        <div>
                                            <el-form label-width="100px" :model="dataForms" ref="dataForms" size="mini" label-position="left">
                                                <el-form-item label="账号ID" prop="id">
                                                    <el-input  maxlength="18" v-model="dataForms.id"></el-input>
                                                </el-form-item>
                                                <el-form-item label="账号名称" prop="username">
                                                    <el-input  maxlength="18" v-model="dataForms.username" placeholder="支持模糊搜索" ></el-input>
                                                </el-form-item>
                                                <el-form-item label="角色ID" prop="roleId">
                                                    <el-input  maxlength="18" v-model="dataForms.roleId"></el-input>
                                                </el-form-item>
                                                <el-form-item label="角色名称" prop="roleName">
                                                    <el-input  maxlength="18" v-model="dataForms.roleName" placeholder="支持模糊搜索"></el-input>
                                                </el-form-item>
                                                <el-form-item label="用户手机号" prop="phone">
                                                    <el-input  maxlength="18" v-model="dataForms.phone"></el-input>
                                                </el-form-item>
                                                <el-form-item label="性别">
                                                    <el-select v-model="dataForms.gender" style="width:100%">
                                                        <el-option label="男" value="1"></el-option>
                                                        <el-option label="女" value="0"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item label="启用状态" prop="status">
                                                    <el-select v-model="dataForms.status" style="width:100%">
                                                        <el-option label="请选择" value=""></el-option>
                                                        <el-option label="启用" value="1"></el-option>
                                                        <el-option label="冻结" value="0"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item label="枚举类型" prop="roleEnum">
                                                    <el-select v-model="dataForms.roleEnum" style="width:100%">
                                                        <el-option label="请选择" value=""></el-option>
                                                        <el-option label="customer" value="customer"></el-option>
                                                        <el-option label="driver" value="driver"></el-option>
                                                        <el-option label="carrier " value="carrier"></el-option>
                                                    </el-select>
                                                </el-form-item>
<!--                                                <CityLinkageReg @addlist="getCity" :addList="addList"></CityLinkageReg>-->
                                                    <CountriesLink @addlist="getCity" :addList="addList"></CountriesLink>
                                                <el-form-item label="邮箱" prop="email">
                                                    <el-input  maxlength="18" v-model="dataForms.email"></el-input>
                                                </el-form-item>
                                                <el-form-item label="所属公司编码" prop="companyId">
                                                    <el-input  maxlength="18" v-model="dataForms.companyId"></el-input>
                                                </el-form-item>
                                                <el-form-item label="所属公司名称" prop="companyName">
                                                    <el-input  maxlength="18" v-model="dataForms.companyName"></el-input>
                                                </el-form-item>
                                            </el-form>
                                            <div slot="footer" class="dialog-footer" style="text-align: center">
                                                <!--<el-button type="primary" @click="$refs.drawer.closeDrawer()" :loading="loading">{{ loading ? '提交中 ...' : '确 定' }}</el-button>-->
                                                <el-button size="mini" v-if="userManagementSearchFlag" type="primary" @click="handleClose" :loading="loading">{{ loading ? '提交中 ...' : '查 询' }}</el-button>
                                                <el-button size="mini" type="warning" @click="cancelForm">关闭</el-button>
                                            </div>
                                        </div>
                                    </el-card>
                                </div>
                            </el-col>

                        </el-row>
                    </div>
                </el-drawer>
            </el-row>
        </div>
        <div class="tableMain">
            <ces-table
                    size='mini'
                    :isSelection='true'
                    :isIndex='true'
                    :isPagination='true'
                    :isHandle='true'
                    v-loading='loading'
                    :tableData='tableData'
                    :tableCols='tableCols'
                    :pagination='pagination'
                    @refresh="handleCurrentChange"
                    ref='cesTable'
            >
            </ces-table>
        </div>
        <el-dialog
                :title="dialogTitleUserManagement"
                :visible.sync="dialog_userManagement"
                width="85%"
                :modal="true"
                center
                :before-close="handleClose_userManagement">
            <el-card class="box-card" :body-style="{ padding: '10px' }">
                <div slot="header">
                    <span style="font-size: 14px">用户信息</span>
                </div>
                <div>
                    <el-form :model="userManagementForm"  ref="userManagementForm" :rules="userManagementFormrules" label-width="110px" class="demo-ruleForm" size="mini" label-position="center">
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="账户名" prop="username">
                                    <el-input v-model="userManagementForm.username" :disabled="userManageModifyFlag[0]"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6" v-if="userManageModifyFlag[1]">
                                <el-form-item label="密码" prop="password">
                                    <el-input v-model="userManagementForm.password"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="用户真实姓名" prop="name">
                                    <el-input v-model="userManagementForm.name"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="性别" prop="gender">
                                    <el-select v-model="userManagementForm.gender">
                                        <el-option label="男" value="1"></el-option>
                                        <el-option label="女" value="0"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="用户手机号" prop="phone">
                                    <el-input v-model="userManagementForm.phone" :disabled="userManageModifyFlag[2]"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="固话" prop="telephone">
                                    <el-input v-model="userManagementForm.telephone"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="邮箱" prop="email">
                                    <el-input v-model="userManagementForm.email"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="用户状态" prop="status">
                                    <el-select v-model="userManagementForm.status">
                                        <el-option label="启用" value="1"></el-option>
                                        <el-option label="冻结" value="0"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="所属系统" prop="userType">
                                    <el-select v-model="userManagementForm.userType">
                                        <el-option label="城市配" value="1"></el-option>
                                        <el-option label="系统管理" value="0"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="所属公司" prop="companyName">
                                    <el-input v-model="userManagementForm.companyName" @focus="companyFocus"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="角色" prop="roleName">
                                    <el-input v-model="userManagementForm.roleName" @focus="roleFocus"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <CityLinkageReg @addlist="getCity" :addList="addList"></CityLinkageReg>
                            </el-col>
                          <el-col :span="6">
                            <el-form-item label="租户" prop="tenantName">
                              <el-input v-model="userManagementForm.tenantName" @focus="tenantFocus">
                              </el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>

                    </el-form>

                </div>
            </el-card>
            <span slot="footer" class="dialog-footer" style="text-align: center">
                <el-button type="primary" @click="save_userManagement">提 交</el-button>
                <el-button @click="handleClose_userManagement">关 闭</el-button>
            </span>
        </el-dialog>
        <el-dialog title="公司查询" :visible.sync="dialogTableVisible">
            <el-row>
                <el-form :inline="true"  v-model="form" size="mini" label-position="center">
                    <el-col :span="9">
                        <div class="grid-content">
                            <el-form-item label="编码" label-width="60px" style="width: 220px;">
                                <el-input size="mini" v-model="form.companyId" style="width: 150px;"></el-input>
                            </el-form-item>
                        </div>
                    </el-col>
                    <el-col :span="9">
                        <div class="grid-content">
                            <el-form-item label="名称" label-width="60px" style="width: 220px;">
                                <el-input size="mini" v-model="form.companyName" style="width: 150px;"></el-input>
                            </el-form-item>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="grid-content">
                            <el-button type="primary" plain size="mini" @click="companySearch">查询</el-button>
                            <el-button type="primary" plain size="mini" @click="companyOkay">确定</el-button>
                        </div>
                    </el-col>
                </el-form>
            </el-row>
            <el-row class="waybill_add_dialog">
                <flight-table
                        :tableData="tableDatadialog"
                        :columns="columns"
                        :pagesizes="pagesizes"
                        :isPagination='true'
                        @handleSelectionChange="handleSelectionChange"
                        @clickTable="clickTable"
                        @handleCurrentChange="handleCurrentChange_compan"
                        @handleSizeChange = 'handleSizeChange'
                        :total="tableDatadialog_pagination.total"
                        :pageSize="tableDatadialog_pagination.pageSize"
                        :current-page="tableDatadialog_pagination.pageNum"
                ></flight-table>
            </el-row>
        </el-dialog>
        <el-dialog title="角色查询" :visible.sync="dialogTableVisible_role">
            <el-row>
                <el-form :inline="true"  v-model="form_role" size="mini" label-position="center">
                    <el-col :span="9">
                        <div class="grid-content">
                            <el-form-item label="编码" label-width="60px" style="width: 220px;">
                                <el-input size="mini" v-model="form_role.roleId" style="width: 150px;"></el-input>
                            </el-form-item>
                        </div>
                    </el-col>
                    <el-col :span="9">
                        <div class="grid-content">
                            <el-form-item label="名称" label-width="60px" style="width: 220px;">
                                <el-input size="mini" v-model="form_role.roleName" style="width: 150px;"></el-input>
                            </el-form-item>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="grid-content">
                            <el-button type="primary" plain size="mini" @click="companySearch_role">查询</el-button>
                            <el-button type="primary" plain size="mini" @click="companyOkay_role">关闭</el-button>
                        </div>
                    </el-col>
                </el-form>
            </el-row>
            <el-row class="waybill_add_dialog">
                <flight-table
                        :tableData="tableDatadialog_role"
                        :columns="columns_role"
                        :pagesizes="pagesizes_role"
                        :isPagination='true'
                        @handleSelectionChange="handleSelectionChange_role"
                        @clickTable="clickTable_role"
                        @handleCurrentChange="handleCurrentChange_role"
                        @handleSizeChange = 'handleSizeChange_role'
                        :total="tableDatadialog_pagination_role.total"
                        :pageSize="tableDatadialog_pagination_role.pageSize"
                        :current-page="tableDatadialog_pagination_role.pageNum"
                ></flight-table>
            </el-row>
        </el-dialog>
      <el-dialog title="租户查询" :visible.sync="dialogTableVisible_tenant">
        <el-row>
          <el-form :inline="true"  v-model="form_tenant" size="mini" label-position="center">
            <el-col :span="9">
              <div class="grid-content">
                <el-form-item label="编码" label-width="60px" style="width: 220px;">
                  <el-input size="mini" v-model="form_tenant.tenantId" style="width: 150px;"></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="9">
              <div class="grid-content">
                <el-form-item label="名称" label-width="60px" style="width: 220px;">
                  <el-input size="mini" v-model="form_tenant.tenantName" style="width: 150px;"></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content">
                <el-button type="primary" plain size="mini" @click="companySearch_tenant">查询</el-button>
                <el-button type="primary" plain size="mini" @click="companyOkay_tenant">确定</el-button>
              </div>
            </el-col>
          </el-form>
        </el-row>
        <el-row class="waybill_add_dialog">
          <flight-table
              :tableData="tenantDatadialog"
              :columns="tenantcolumns"
              :pagesizes="pagesizes"
              :isPagination='true'
              @handleSelectionChange="handleSelectionChange_tenant"
              @clickTable="clickTable_tenant"
              @handleCurrentChange="handleCurrentChange_tenant"
              @handleSizeChange = 'handleSizeChange_tenant'
              :total="tenantDatadialog_pagination.total"
              :pageSize="tenantDatadialog_pagination.pageSize"
              :current-page="tenantDatadialog_pagination.pageNum"
          ></flight-table>
        </el-row>
      </el-dialog>
    </div>
</template>

<script>
    //引入组件
    import cesTable from '../../components/common/table.vue'
    import Drawer from '../../components/common/drawer.vue'
    import axios from 'axios'
    import qs from 'qs'
    import arrayQuery from '../../components/arrayQuery.js'
    import 'element-ui/lib/theme-chalk/index.css'
    import flightTable from "../../components/common/flightTable.vue"
    import CityLinkageReg from "../../components/common/cityLinkageReg.vue"
    import CountriesLink from "../../components/common/countriesLink.vue"
    export default {
        data(){
            return {
                table: false,
                dialog: false,
                loading: false,
                dialogTitleUserManagement:'',
                dialog_userManagement:false,
                dialogTableVisible: false,
                dialogTableVisible_role: false,
                dialogTableVisible_tenant: false,
                tableDatadialog: [],
              tenantDatadialog:[],
                tableDatadialog_role: [],
                companyData:[],
                roleData:[],
                userManageModifyFlag:[false,true,false],
                form:{
                    companyId:'',
                    companyName:''
                },
                form_role:{
                    roleId:'',
                    roleName:''
                },
              form_tenant :{
                tenantId:'',
                tenantName:''
              },
                columns: [
                    {
                        id: "selection",
                        type: "selection",
                        label: "",
                        fixed: "left",
                        width: "55",
                        prop: "",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "companyId",
                        type: "",
                        label: "编码",
                        fixed: false,
                        width: "120",
                        prop: "companyId",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "companyName",
                        type: "",
                        label: "名称",
                        fixed: false,
                        width: "180",
                        prop: "companyName",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "companyType",
                        type: "",
                        label: "使用类型",
                        fixed: false,
                        width: "130",
                        prop: "companyType",
                        isShow: true,
                        align: "center",
                        formatter: (row) => {
                            if (row.companyType == '1') {
                                return "承运商/司机";
                            } else if (row.companyType == '0') {
                                return "普通用户";
                            } else {
                                return "";
                            }
                        }
                    },
                    {
                        id: "moduleId",
                        type: "",
                        label: "所属模块",
                        fixed: false,
                        width: "130",
                        prop: "moduleId",
                        isShow: true,
                        align: "center",
                        formatter: (row) => {
                            if (row.moduleId == '1') {
                                return "城市配";
                            } else if (row.moduleId == '0') {
                                return "系统管理";
                            } else {
                                return "";
                            }
                        }
                    }
                ],
                columns_role: [
                    {
                        id: "selection",
                        type: "selection",
                        label: "",
                        fixed: "left",
                        width: "55",
                        prop: "",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "roleId",
                        type: "",
                        label: "编码",
                        fixed: false,
                        width: "80",
                        prop: "roleId",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "roleName",
                        type: "",
                        label: "角色名称",
                        fixed: false,
                        width: "240",
                        prop: "roleName",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "roleEnum",
                        type: "",
                        label: "枚举名称",
                        fixed: false,
                        width: "110",
                        prop: "roleEnum",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "moduleId",
                        type: "",
                        label: "所属模块",
                        fixed: false,
                        width: "130",
                        prop: "moduleId",
                        isShow: true,
                        align: "center",
                        formatter: (row) => {
                            if (row.moduleId == '1') {
                                return "城市配";
                            } else if (row.moduleId == '0') {
                                return "系统管理";
                            } else {
                                return "";
                            }
                        }
                    }
                ],
              tenantcolumns: [
                {
                  id: "selection",
                  type: "selection",
                  label: "",
                  fixed: "left",
                  width: "55",
                  prop: "",
                  isShow: true,
                  align: "center"
                },
                {
                  id: "tenantId",
                  type: "",
                  label: "编码",
                  fixed: false,
                  width: "200",
                  prop: "tenantId",
                  isShow: true,
                  align: "center"
                },
                {
                  id: "tenantName",
                  type: "",
                  label: "名称",
                  fixed: false,
                  width: "300",
                  prop: "tenantName",
                  isShow: true,
                  align: "center"
                },
              ],
              tenantDatadialog_pagination:{
                pageSize:10,
                pageNum:1,
                total:0
              },
                tableDatadialog_pagination:{
                    pageSize:10,
                    pageNum:1,
                    total:0
                },
              tenantDatadialog_pagination_role:{
                pageSize:10,
                pageNum:1,
                total:0
              },
                tableDatadialog_pagination_role:{
                    pageSize:10,
                    pageNum:1,
                    total:0
                },
                pagesizes: [5, 10, 15, 20],
                pagesizes_role: [5, 10, 15, 20],
                AccurateAndUnique_name:'',
                dataForms:{
                    id:'',
                    username:'',
                    name:'',
                    gender:'',
                    roleEnum:'',
                    phone:'',
                    email:'',
                    status:'',
                    companyId:'',
                    companyName:'',
                    roleId:'',
                    roleName:'',
                    tenantId:''
                },
                userManagementForm:{
                    id:'',
                    username: '',
                    password:'',
                    name:'',
                    gender:'1',
                    phone:'',
                    telephone: '',
                    email:'',
                    status:'1',
                    userType:'1',
                    companyId:'',
                    companyName:'',
                    roleId:'',
                    roleName:'',
                   tenantId:'',
                   tenantName:''
                },
                userManagementFormrules:{
                    username: [
                        { required: true, message: '请输入用户名', trigger: 'blur' },
                        { min: 7, max: 12, message: "请填写7-12位数字字母组合", trigger: "change" },
                        {
                            pattern: '^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{7,12}$',
                            message: "用户名必须数字+字母组合",
                            trigger: "blur"
                        }
                    ],
                    password: [
                        { required: true,message: '请输入密码', trigger: 'blur' },
                        { min: 8, max: 12, message: "密码需要8-12位字母、数字、特殊字符", trigger: "change" },
                        {
                            pattern: '^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^(0-9a-zA-Z)]).{8,12}$',
                            message: "密码设置需要数字+字母+特殊符合",
                            trigger: "blur"
                        }
                    ],
                  status: [
                    { required: true,message: '请选择状态', trigger: 'blur' }
                  ],
                  tenantName: [
                    { required: true,message: '请输入租户名称', trigger: 'blur' }
                  ],
                    name: [
                        { required: true, message: '请输入您的真实姓名', trigger: 'blur' }
                    ],
                    roleName: [
                        { required: true, message: '角色必填', trigger: ['blur', 'change'] }
                    ],
                    companyName: [
                        { required: true, message: '公司必填', trigger: ['blur', 'change'] }
                    ],
                    email: [
                        { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
                    ],
                    phone:[
                        { required: true, message: "请输入手机号码", trigger: "blur" },
                        { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
                        {
                            pattern: '^1[345789]\\d{9}$',
                            message: "请输入正确的手机号码",
                            trigger: "blur"
                        }
                    ]
                },
                formLabelWidth: '80px',
                timer: null,
                apiId:'null',
                fieldList:[],
                // 表格
                tableData:[],//表格数据
                tableCols:[ //表格列数据
                    {label:'用户id',prop:'id',width:'120',align:'center'},
                    {label:'账号名称',prop:'username',align:'center'},
                    {label:'用户姓名',prop:'name',align:'center',width:'120'},
                    {label:'性别',prop:'gender',align:'center',width:'120',
                        formatter: (row) => {
                            if (row.gender == '1') {
                                return "男";
                            } else if (row.gender == '0') {
                                return "女";
                            } else {
                                return "";
                            }
                        }
                    },
                    {label:'用户手机号',prop:'phone',align:'center',width:'120'},
                    {label:'用户座机',prop:'telephone',align:'center',width:'120'},
                    {label:'用户邮箱',prop:'email',align:'center',width:'120'},
                    {label:'启用状态',prop:'status',align:'center',
                        formatter: (row) => {
                            if (row.status == '1') {
                                return "启用";
                            } else if (row.status == '0') {
                                return "冻结";
                            } else {
                                return "";
                            }
                        }
                    },
                    {label:'用户类型',prop:'userType',align:'center',
                        formatter: (row) => {
                            if (row.userType == '1') {
                                return "城市配";
                            } else if (row.userType == '0') {
                                return "系统管理";
                            } else {
                                return "";
                            }
                        }
                    },
                    {label:'用户所属公司ID',prop:'companyId',align:'center',width:'120'},
                    {label:'用户所属公司名称',prop:'companyName',align:'center',width:'160'},
                    {label:'用户所属角色ID',prop:'roleId',align:'center',width:'120'},
                    {label:'角色名称',prop:'roleName',align:'center'},
                    {label:'角色枚举类',prop:'roleEnum',align:'center',width:'120'},
                    {label:'省级名称',prop:'proviceName',align:'center',width:'80'},
                    {label:'市级名称',prop:'cityName',align:'center',width:'80'},
                    {label:'区级名称',prop:'regionName',align:'center',width:'80'},
                  {label:'租户ID',prop:'tenantId',width:'80',align:'center'},
                  {label:'租户名称',prop:'tenantName',width:'120',align:'center'},
                    {label:'创建人',prop:'creator',align:'center'},
                    {label:'创建时间',prop:'createTime',align:'center',width:'160',
                        formatter: (row, column) => {
                            if(row.createTime == null){
                                return ''
                            }else {
                                return this.$moment(row.createTime).format("YYYY-MM-DD HH:mm:ss")
                            }
                        }
                    },
                    {label:'修改人',prop:'modifier',align:'center'},
                    {label:'修改时间',prop:'modifyTime',align:'center',width:'160',
                        formatter: (row, column) => {
                            if(row.modifyTime == null){
                                return ''
                            }else {
                                return this.$moment(row.modifyTime).format("YYYY-MM-DD HH:mm:ss")
                            }
                        }
                    }
                ],
                pagination:{ //分页数据
                    pageSize:50,
                    pageNum:1,
                    total:0
                },
                merge:[],
                userPowerButtons:[],
                userManagementSearchFlag:true,
                userManagementAddFlag:true,
                userManagementModifyFlag:true,
                userManagementFrozenFlag:true,
                userManagementDelFlag:true,
                addList: {
                    province: "",
                    city: "",
                    area: ""
                }
            }
        },
        components:{ //引入组件后注册组件
            cesTable,
            Drawer,
            flightTable,
            CityLinkageReg,
             CountriesLink
        },
        mounted(){
            this.getTableAndForm();
            //用户上级菜单权限管理id
            let parentId = '1_1';
            let currentPageButtons = arrayQuery.text(parentId);
            let _this = this;
            _this.currentPageButtons = currentPageButtons;
            //查询按钮权限
            let userManagementSearch = currentPageButtons.find(item => {
                return item.menuId == '1_1_1';
            });
            if(userManagementSearch == undefined){
                _this.userManagementSearchFlag = false;
            }else {
                _this.userManagementSearchFlag = true;
            }
            //新增按钮权限
            let userManagementAdd = currentPageButtons.find(item => {
                return item.menuId == '1_1_2';
            });
            if(userManagementAdd == undefined){
                _this.userManagementAddFlag = false;
            }else {
                _this.userManagementAddFlag = true;
            }
            //修改按钮权限
            let userManagementModify = currentPageButtons.find(item => {
                return item.menuId == '1_1_3';
            });
            if(userManagementModify == undefined){
                _this.userManagementModifyFlag = false;
            }else {
                _this.userManagementModifyFlag = true;
            }
            //删除按钮权限
            let userManagementDel = currentPageButtons.find(item => {
                return item.menuId == '1_1_4';
            });
            if(userManagementDel == undefined){
                _this.userManagementDelFlag = false;
            }else {
                _this.userManagementDelFlag = true;
            }
            //冻结按钮权限
            let userManagementFrozen = currentPageButtons.find(item => {
                return item.menuId == '1_1_5';
            });
            if(userManagementFrozen == undefined){
                _this.userManagementFrozenFlag = false;
            }else {
                _this.userManagementFrozenFlag = true;
            }
        },
        methods:{
            getCity(data) {
                this.addList.province = data.province;
                this.addList.city = data.city;
                this.addList.area = data.area;
            },



          // checkbox的触发接收
          handleSelectionChange_tenant(val) {
            this.companyData = val.val;
          },
          handleClose_tenantAPPoval(){
            this.dialog_tenantAPPoval = false;
            this.$refs.userManagementForm.resetFields();
            this.tenantManagementSearch();
          },
          //公司
          tenantFocus(){
            this.companySearch_tenant();
            this.dialogTableVisible_tenant = true;
          },
          //查询
          tenantManagementSearch(){
            this.loading = true;
            const data = new URLSearchParams();
            data.append('tenantName', this.dataForms.tenantName);
            data.append('tenantId', this.dataForms.tenantId);
            data.append('status', this.dataForms.status);
            data.append('pageSize', this.pagination.pageSize);
            data.append('pageNo', this.pagination.pageNum);
            const params = Object.fromEntries(data.entries());
            console.log("tenantName",params);
            axios({
              method: 'post',
              url: `${this.$apiPath}/api/pm/tenant/queryTenantsPage`,
              data:data,
            }).then(res=> {
              this.tableData = res.data.items;
              this.pagination.total = res.data.total;
              this.loading = false;
            });
          },
          // 双击事件的行触发
          clickTable_tenant(val) {
            // 深拷贝浅拷贝
            const data = JSON.parse(JSON.stringify(val));
            this.userManagementForm.tenantId = data.row.tenantId;
            this.userManagementForm.tenantName = data.row.tenantName;
            console.log('赋值之后的form',this.userManagementForm);
            this.dialogTableVisible_tenant = false;
          },
          // clickTable(val) {
          //   console.log('双击获取的值',val);
          //   this.companyManagementForm.tenantId = val.row.tenantId;
          //   this.companyManagementForm.tenantName = val.row.tenantName;
          //   this.dialogTableVisible = false;
          // },
          companyOkay_tenant(){
            console.log("companyData",this.companyData);
            if(this.companyData.length == 0){
              this.$message({
                message: '请先勾选一条公司信息',
                type: 'warning'
              });
            }else if(this.companyData.length > 1){
              this.$message({
                message: '新增用户仅允许勾选一条公司信息',
                type: 'warning'
              });
            }else {

              this.userManagementForm.tenantId = this.companyData[0].tenantId;
              this.userManagementForm.tenantName = this.companyData[0].tenantName;
              this.dialogTableVisible_tenant = false;
            }
          },
          handleSizeChange_tenant(val){
            this.tenantDatadialog_pagination.pageSize = val;
            this.companySearch_tenant();
          },
          handleCurrentChange_tenant(val) {
            this.tenantDatadialog_pagination.pageNum = val;
            this.companySearch_tenant();
          },
          companySearch_tenant(){
            const data = new URLSearchParams();
            data.append('tenantId', this.form_tenant.tenantId);
            data.append('tenantName', this.form_tenant.tenantName);
            data.append('pageSize', this.tenantDatadialog_pagination.pageSize);
            data.append('pageNo', this.tenantDatadialog_pagination.pageNum);
            axios({
              method: 'post',
              url: `${this.$apiPath}/api/pm/tenant/queryTenantCheckPage`,
              data:data
            }).then(res=> {
              this.tenantDatadialog = res.data.items;
              this.tenantDatadialog_pagination.total = res.data.total;
            });
          },




            handleSizeChange(val){
                this.tableDatadialog_pagination.pageSize = val;
                this.companySearch();
            },
            handleSizeChange_role(val){
                this.tableDatadialog_pagination_role.pageSize = val;
                this.companySearch_role();
            },
            // checkbox的触发接收
            handleSelectionChange(val) {
                this.companyData = val.val;
            },
            // checkbox的触发接收
            handleSelectionChange_role(val) {
                this.roleData = val.val;
            },
            // 双击事件的行触发
            clickTable(val) {
                this.userManagementForm.companyId = val.row.companyId;
                this.userManagementForm.companyName = val.row.companyName;
                this.dialogTableVisible = false;
            },
            // 双击事件的行触发
            clickTable_role(val) {
                this.userManagementForm.roleId = val.row.roleId;
                this.userManagementForm.roleName = val.row.roleName;
                this.dialogTableVisible_tenant = false;
            },
            handleCurrentChange_compan(val) {
                this.tableDatadialog_pagination.pageNum = val;
                this.companySearch();
            },
            handleCurrentChange_role(val) {
                this.tableDatadialog_pagination_role.pageNum = val;
                this.companySearch_role();
            },
            companySearch(){
                const data = new URLSearchParams();
                data.append('companyId', this.form.companyId);
                data.append('companyName', this.form.companyName);
                data.append('pageSize', this.tableDatadialog_pagination.pageSize);
                data.append('pageNo', this.tableDatadialog_pagination.pageNum);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/pm/company/queryCommonCompanyCheckPage`,
                    data:data
                }).then(res=> {
                    this.tableDatadialog = res.data.items;
                    this.tableDatadialog_pagination.total = res.data.total;
                });
            },
            companySearch_role(){
                const data = new URLSearchParams();
                data.append('roleId', this.form_role.roleId);
                data.append('roleName', this.form_role.roleName);
                data.append('pageSize', this.tableDatadialog_pagination_role.pageSize);
                data.append('pageNo', this.tableDatadialog_pagination_role.pageNum);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/pm/role/queryCommonRoleCheckPage`,
                    data:data
                }).then(res=> {
                    this.tableDatadialog_role = res.data.items;
                    this.tableDatadialog_pagination_role.total = res.data.total;
                });
            },
            companyOkay(){
                if(this.companyData.length == 0){
                    this.$message({
                        message: '请先勾选一条公司信息',
                        type: 'warning'
                    });
                }else if(this.companyData.length > 1){
                    this.$message({
                        message: '新增用户仅允许勾选一条公司信息',
                        type: 'warning'
                    });
                }else {
                    this.userManagementForm.companyId = this.companyData[0].companyId;
                    this.userManagementForm.companyName = this.companyData[0].companyName;
                    this.dialogTableVisible = false;
                }
            },
            companyOkay_role(){
                if(this.roleData.length == 0){
                    this.$message({
                        message: '请先勾选一条角色信息',
                        type: 'warning'
                    });
                }else if(this.roleData.length > 1){
                    this.$message({
                        message: '新增用户仅允许勾选一条角色信息',
                        type: 'warning'
                    });
                }else {
                    this.userManagementForm.companyId = this.roleData[0].companyId;
                    this.userManagementForm.companyName = this.roleData[0].companyName;
                    this.dialogTableVisible_role = false;
                }
            },
            //保存数据
            save_userManagement(){
                this.$refs["userManagementForm"].validate(valid => {
                    if (valid) {
                        const data = new URLSearchParams();
                        data.append('id', this.userManagementForm.id);
                        data.append('username', this.userManagementForm.username);
                        data.append('password', this.userManagementForm.password);
                        data.append('name', this.userManagementForm.name);
                        data.append('gender', this.userManagementForm.gender);
                        data.append('phone', this.userManagementForm.phone);
                        data.append('telephone', this.userManagementForm.telephone);
                        data.append('email', this.userManagementForm.email);
                        data.append('status',this.userManagementForm.status);
                        data.append('companyId', this.userManagementForm.companyId);
                        data.append('roleId',this.userManagementForm.roleId);
                        data.append('userType',this.userManagementForm.userType);
                        data.append('proviceAdcode', this.addList.province);
                        data.append('cityAdcode',this.addList.city);
                        data.append('regionAdcode',this.addList.area);
                      data.append('tenantId', this.userManagementForm.tenantId);
                      data.append('tenantName', this.userManagementForm.tenantName);
                        if(this.dialogTitleUserManagement == '新增用户'){
                            axios({
                                method: 'post',
                                url: `${this.$apiPath}/api/pm/user/addUserInfos`,
                                data:data
                            }).then(res=> {
                                if(res.data.success == true){
                                    this.$message({
                                        message: '新增成功',
                                        type: 'success'
                                    });
                                    this.handleClose_userManagement();
                                    this.userManagementSearch();
                                }else {
                                    this.$alert(res.data.msg, '提示', {
                                        confirmButtonText: '确定',
                                    });
                                    this.handleClose_userManagement();
                                }
                            });
                        }else if(this.dialogTitleUserManagement == '修改用户'){
                            axios({
                                method: 'post',
                                url: `${this.$apiPath}/api/pm/user/updateUserInfos`,
                                data:data
                            }).then(res=> {
                                if(res.data.success == true){
                                    this.$message({
                                        message: '修改成功',
                                        type: 'success'
                                    });
                                    this.handleClose_userManagement();
                                    this.userManagementSearch();
                                }else {
                                    this.$alert(res.data.msg, '提示', {
                                        confirmButtonText: '确定',
                                    });
                                    this.handleClose_userManagement();
                                }
                            });
                        }
                    } else {
                        this.$message.error("请检查您输入的数据,请核对后重试!");
                    }
                });

            },
            //公司
            companyFocus(){
                this.companySearch();
                this.dialogTableVisible = true;
            },
            //角色
            roleFocus(){
                this.companySearch_role();
                this.dialogTableVisible_role = true;
            },
            handleClose_userManagement(){
                this.dialog_userManagement = false;
                this.$refs.userManagementForm.resetFields();
                this.addList.province = '';
                this.addList.city = '';
                this.addList.area = '';
            },
            //此方法是点击分页时触发的查询，
            handleCurrentChange(){
                this.userManagementSearch()
            },
            //获取表格和表单数据
            async getTableAndForm(){
                this.userManagementSearch()
            },
            handleQuery(){ //查询
                this.pagination.pageSize = 50;
                this.pagination.pageNum = 1;
                this.pagination.total = 0;
                //快捷查询
                this.loading = true;
                const data = new URLSearchParams();
                data.append('name', this.AccurateAndUnique_name);
                data.append('pageSize', this.pagination.pageSize);
                data.append('pageNo', this.pagination.pageNum);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/pm/user/queryUserInfos`,
                    data:data
                }).then(res=> {
                    this.tableData = res.data.items;
                    this.pagination.total = res.data.total;
                    this.loading = false;
                });
            },
            // 获取表格勾选数据
            selectionLineChangeHandle(val){
                this.merge = this.$refs.cesTable.multipleSelection
            },
            //查询
            userManagementSearch(){
                this.loading = true;
                const data = new URLSearchParams();
                data.append('id', this.dataForms.id);
                data.append('username', this.dataForms.username);
                data.append('name', this.dataForms.name);
                data.append('gender', this.dataForms.gender);
                data.append('roleEnum', this.dataForms.roleEnum);
                data.append('phone', this.dataForms.phone);
                data.append('email', this.dataForms.email);
                data.append('status',this.dataForms.status);
                data.append('companyId', this.dataForms.companyId);
                data.append('companyName', this.dataForms.companyName);
                data.append('roleId',this.dataForms.roleId);
                data.append('roleName',this.dataForms.roleName);
                data.append('proviceAdcode',this.addList.province);
                data.append('cityAdcode',this.addList.city);
                data.append('regionAdcode',this.addList.area);
                data.append('pageSize', this.pagination.pageSize);
                data.append('pageNo', this.pagination.pageNum);
              data.append('tenantId', this.dataForms.tenantId);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/pm/user/queryUserInfos`,
                    data:data
                }).then(res=> {
                    this.tableData = res.data.items;
                    this.pagination.total = res.data.total;
                    this.loading = false;
                });
            },
            userManagementSearch_Multiple(){
                this.pagination.pageSize = 50;
                this.pagination.pageNum = 1;
                this.pagination.total = 0;
                this.userManagementSearch();
            },
            //新增
            userManagementAdd(){
                this.dialog_userManagement = true;
                this.userManageModifyFlag = [false,true,false];
                this.dialogTitleUserManagement = '新增用户';
            },
            //修改
            userManagementModify(){
                this.selectionLineChangeHandle();
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '修改操作必须选中一条数据'
                    });
                }else if(this.merge.length > 1){
                    this.$message({
                        type: 'info',
                        message: '修改操作只能选择单条数据进行操作'
                    });
                }else{
                    this.userManageModifyFlag = [true,false,true];
                    this.dialogTitleUserManagement = '修改用户';
                    axios.get(`${this.$apiPath}/api/pm/user/findUserInfosById/`+ this.merge[0].id).then(res=>{
                        if(res.data.data.status == true){
                            res.data.data.status = '1'
                        }else if(res.data.data.status == false){
                            res.data.data.status = '0'
                        }
                        if(res.data.data.gender == true){
                            res.data.data.gender = '1'
                        }else if(res.data.data.gender == false){
                            res.data.data.gender = '0'
                        }
                        res.data.data.userType = JSON.stringify(res.data.data.userType);
                        let processedData = this.nullToStr(res.data.data);
                        this.userManagementForm = processedData;
                        this.addList.province = processedData.proviceAdcode;
                        this.addList.city = processedData.cityAdcode;
                        this.addList.area = processedData.regionAdcode;
                        this.dialog_userManagement = true;
                    })
                }
            },
            nullToStr(data) {
                for (var x in data) {
                    if (data[x] === null) { // 如果是null 把直接内容转为 ''
                        data[x] = '';
                    } else {
                        if (Array.isArray(data[x])) { // 是数组遍历数组 递归继续处理
                            data[x] = data[x].map(z => {
                                return nullToStr(z);
                            });
                        }
                        if(typeof(data[x]) === 'object'){ // 是json 递归继续处理
                            data[x] = nullToStr(data[x])
                        }
                    }
                }
                return data;
            },
            //冻结
            userManagementFrozen(){
                this.selectionLineChangeHandle();
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '冻结操作必须选中一条数据'
                    });
                }else if(this.merge.length > 1){
                    this.$message({
                        type: 'info',
                        message: '冻结操作只能选择单条用户数据进行操作'
                    });
                }else{
                    this.$confirm('此操作将对用户进行冻结, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        axios.get(`${this.$apiPath}/api/pm/user/updateUserStatus/`+ this.merge[0].id).then(res=>{
                            if(res.data.success == true){
                                this.$message({
                                    message: '冻结成功',
                                    type: 'success'
                                });
                                this.userManagementSearch();
                            }else {
                                this.$alert(res.data.msg, '提示', {
                                    confirmButtonText: '确定',
                                });
                            }
                        })
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消操作'
                        });
                    });
                }
            },
            //删除
            userManagementDel(){
                this.selectionLineChangeHandle();
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '删除操作必须选中一条数据'
                    });
                }else if(this.merge.length > 1){
                    this.$message({
                        type: 'info',
                        message: '删除操作只能选择单条用户数据进行操作'
                    });
                }else{
                    this.$confirm('此操作将对用户进行删除, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        axios.get(`${this.$apiPath}/api/pm/user/deleteUserInfosById/`+ this.merge[0].id).then(res=>{

                            if(res.data.success == true){
                                this.$message({
                                    message: '删除成功',
                                    type: 'success'
                                });
                                this.userManagementSearch();
                            }else {
                                this.$alert(res.data.msg, '提示', {
                                    confirmButtonText: '确定',
                                });
                            }
                        })
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消操作'
                        });
                    });
                }
            },
            handleClose(done) {
                if (this.loading) {
                    return;
                }
                this.loading = true;
                this.timer = setTimeout(() => {
                    // 动画关闭需要一定的时间
                    setTimeout(() => {
                        this.loading = false;
                    }, 400);
                    this.userManagementSearch_Multiple()
                    // 动画关闭需要一定的时间
                    this.dialog = false;
                }, 2000);
            },
            cancelForm() {
                this.loading = false;
                this.dialog = false;
                this.addList.province = '';
                this.addList.city = '';
                this.addList.area = '';
                clearTimeout(this.timer);
            }
        }
    }
</script>
<style>
    .tableMain .ces-table-page{
        height: 100%;
    }
    .tableMain .ces-table{
        height: 100%;
    }
    .tableMain .el-table th{
        padding: 0;
        height: 35px;
    }
    .tableMain .el-table td{
        padding: 0;
        height: 35px;
    }
    .waybill_add_dialog .ces-table{
        height: 200px;
    }
    .waybill_add_dialog .el-table th{
        padding: 0;
        height: 30px;
    }
    .waybill_add_dialog .el-table td{
        padding: 0;
        height: 30px;
    }
</style>
<style scoped>

    .boxbutton{
        width: 100%;
        height: 40px;
    }
    .tableMain{
        width: 100%;
        height: 82%;
    }
    .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item{
        margin-bottom: 14px;
    }
</style>
